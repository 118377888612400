import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TextInput } from '../../atoms/text-input';
import { RadioButton } from '../../atoms/radio-button';
import { Button } from '../../atoms/button';
import { TimesRange } from '../../molecules/times-range';
import { useFormValidation } from '../../../hooks/form-validation';
import { useSalesforceApi } from '../../../hooks/salesforce';
import { filterAnswersForSubmission } from '../../../utils/index';
import { answersType } from '../../../types';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

export const CallbackForm = ({ answers }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [chooseTime, setChooseTime] = useState(false);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		const todaysDate = new Date().toISOString().split('T')[0];

		if (!isValid) return;

		const isSelectedTimes = formEntries.callFrom && formEntries.callTo;
		const selectedDate = isSelectedTimes ? formEntries.callDay : todaysDate;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			answers: filterAnswersForSubmission(answers),
			details: {
				firstName: formEntries.firstName,
				lastName: formEntries.lastName,
				phoneNumber: formEntries.phoneNumber,
				selectedDate,
				selectedTime,
				contactType: 'Phone-call',
			},
		};
		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		switch (response.status) {
			case 200:
				navigate('/success', {
					state: {
						headingEnd: isSelectedTimes
							? `between the hours of ${formEntries.callFrom} and ${formEntries.callTo}.`
							: 'as soon as possbile.',
						reference: response.body.identifier,
						tracking: {
							id: 'arrange_a_callback',
							data: {
								appointment_urgency: formEntries.callWhen,
								appointment_day: selectedDate,
								appointment_time: selectedTime,
							},
						},
					},
				});
				break;
			case 403:
				navigate('/error', {
					state: {
						errorMessage:
							'Unfortunately, we’ve not been able to verify your Captcha and are unable to submit your form.',
					},
				});
				break;
			default:
				navigate('/error');
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return (
		<form
			onSubmit={submitHandler}
			className="flex flex-col justify-between w-full mx-auto grow"
		>
			<div className="flex flex-col grow">
				<div className="flex flex-col px-4 pt-10 bg-white pb-7 grow">
					<TextInput
						labelText="First name"
						id="firstName"
						name="firstName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your first name"
						showValidation={!validations.firstName.isValid}
						ref={firstNameRef}
					/>

					<TextInput
						labelText="Last name"
						id="lastName"
						name="lastName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your last name"
						showValidation={!validations.lastName.isValid}
						ref={lastNameRef}
					/>

					<TextInput
						labelText="Best number to call you on"
						type="tel"
						id="phoneNumber"
						name="phoneNumber"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter a phone number"
						showValidation={!validations.phoneNumber.isValid}
						ref={phoneNumberRef}
					/>

					<div className="w-full wrapper-small">
						<fieldset className={clsx(chooseTime ? 'mb-4' : null)}>
							<legend className="mb-1 text-brand-blue-400 text-base-f font-centra-medium">
								When shall we call you?
							</legend>

							<p className="mb-4 text-s-f">
								Callbacks can be as quick as within 8 minutes
							</p>

							<div className="flex flex-wrap gap-1">
								<RadioButton
									defaultChecked
									labelText="As soon as possible"
									id="callAsap"
									value="callAsap"
									name="callWhen"
									onChange={() => setChooseTime(false)}
								/>
								<RadioButton
									labelText="Choose a time"
									id="chooseTime"
									value="chooseTime"
									name="callWhen"
									onChange={() => setChooseTime(true)}
								/>
							</div>
						</fieldset>
					</div>
				</div>

				<div
					className="px-4 py-10 bg-brand-blue-200"
					hidden={!chooseTime}
				>
					<TimesRange
						callFromIsValid={
							chooseTime &&
							validations.callFrom &&
							validations.callFrom.isValid
						}
						callToIsValid={
							chooseTime &&
							validations.callTo &&
							validations.callTo.isValid
						}
						isDisabled={!chooseTime}
					/>
				</div>
			</div>

			<div className="px-4 py-12 bg-white">
				<div className="wrapper-small">
					<Button variant="Main" type="submit">
						Request callback
					</Button>
				</div>
			</div>
		</form>
	);
};

CallbackForm.defaultProps = {
	answers: [],
};

CallbackForm.propTypes = {
	answers: answersType,
};
