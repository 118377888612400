import PropTypes from 'prop-types';

export const questionType = PropTypes.shape({
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	question: PropTypes.string.isRequired,
	description: PropTypes.string,
	isMultiple: PropTypes.bool,
	answers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			answer: PropTypes.string,
		})
	).isRequired,
	subQuestions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			question: PropTypes.string.isRequired,
			description: PropTypes.string,
			isMultiple: PropTypes.bool,
			answers: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.oneOfType([
						PropTypes.string,
						PropTypes.number,
					]).isRequired,
					answer: PropTypes.string,
				})
			).isRequired,
		}).isRequired
	),
});

export const answerType = PropTypes.shape({
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string,
	category: PropTypes.string,
	question: PropTypes.string,
	answer: PropTypes.arrayOf(PropTypes.string),
	resultText: PropTypes.string,
	score: PropTypes.number,
});

export const answersType = PropTypes.arrayOf(answerType);

export const gatsbyImageType = PropTypes.objectOf(
	PropTypes.shape({
		allFile: {
			edges: PropTypes.arrayOf(
				PropTypes.objectOf(
					PropTypes.shape({
						node: {
							base: PropTypes.string,
							childImageSharp: {
								gatsbyImageData: {
									layout: PropTypes.string,
									backgroundColor: PropTypes.string,
									images: {
										fallback: {
											src: PropTypes.string,
											srcSet: PropTypes.string,
											sizes: PropTypes.string,
										},
										sources: PropTypes.arrayOf(
											PropTypes.shape({
												srcSet: PropTypes.string,
												type: PropTypes.string,
												sizes: PropTypes.string,
											})
										),
									},
								},
							},
						},
					})
				)
			),
		},
	})
);
