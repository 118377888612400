import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Chevron from '../../../assets/chevron.svg';

const Select = forwardRef(
	(
		{
			options,
			labelText,
			id,
			hintText,
			name,
			validationMessage,
			showValidation,
			className,
			labelClassName,
			...selectAttributes
		},
		ref
	) => {
		const errorId = `${id}-error`;
		const hintTextId = `${id}-hint-text`;

		const renderOption = (option) => (
			<option value={option.value} key={`${option.value}-${id}`}>
				{option.label}
			</option>
		);

		return (
			<div className={clsx('relative', className)}>
				<div className="mb-4">
					<label
						htmlFor={id}
						className={clsx(
							'text-brand-blue-400 text-base-f font-centra-medium',
							labelClassName
						)}
					>
						{labelText}
					</label>
					{hintText ? <p id={hintTextId}>{hintText}</p> : null}
				</div>

				<div className="relative">
					<select
						className={clsx(
							'block px-4 py-3 bg-white border-2 rounded-xs border-blue-200 border-solid hover:bg-brand-pale-400 outline-none w-full appearance-none placeholder:text-grey-400 focus:border-blue-400 focus:placeholder:text-brand-blue-400 transition-colors duration-200 motion-reduce:transition-none',
							showValidation
								? 'border-alert-400 placeholder:text-alert-500'
								: ''
						)}
						name={name}
						id={id}
						aria-invalid={!!showValidation}
						aria-describedby={
							hintText ? `${hintTextId} ${errorId}` : errorId
						}
						ref={ref}
						{...selectAttributes}
						defaultValue=""
					>
						<option value="">Please select</option>
						{options.map(renderOption)}
					</select>

					<Chevron
						className="absolute rotate-180 -translate-y-1/2 pointer-events-none top-1/2 right-4"
						width="14"
						height="14"
						aria-hidden="true"
					/>
				</div>

				{showValidation ? (
					<p className="mt-3 text-alert-500" id={errorId}>
						{validationMessage}
					</p>
				) : null}
			</div>
		);
	}
);

Select.defaultProps = {
	hintText: undefined,
	validationMessage: '',
	showValidation: false,
	className: '',
	labelClassName: '',
};

Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string,
		})
	).isRequired,
	labelText: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hintText: PropTypes.string,
	validationMessage: PropTypes.string,
	showValidation: PropTypes.bool,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
};

export { Select };
