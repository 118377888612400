export const TimesRangeDataSet = [
	{ label: '01:00', value: '1am', key: 1 },
	{ label: '02:00', value: '2am', key: 2 },
	{ label: '03:00', value: '3am', key: 3 },
	{ label: '04:00', value: '4am', key: 4 },
	{ label: '05:00', value: '5am', key: 5 },
	{ label: '06:00', value: '6am', key: 6 },
	{ label: '07:00', value: '7am', key: 7 },
	{ label: '08:00', value: '8am', key: 8 },
	{ label: '09:00', value: '9am', key: 9 },
	{ label: '10:00', value: '10am', key: 10 },
	{ label: '11:00', value: '11am', key: 11 },
	{ label: '12:00', value: '12pm', key: 12 },
	{ label: '13:00', value: '1pm', key: 13 },
	{ label: '14:00', value: '2pm', key: 14 },
	{ label: '15:00', value: '3pm', key: 15 },
	{ label: '16:00', value: '4pm', key: 16 },
	{ label: '17:00', value: '5pm', key: 17 },
	{ label: '18:00', value: '6pm', key: 18 },
	{ label: '19:00', value: '7pm', key: 19 },
	{ label: '20:00', value: '8pm', key: 20 },
	{ label: '21:00', value: '9pm', key: 21 },
	{ label: '22:00', value: '10pm', key: 22 },
	{ label: '23:00', value: '11pm', key: 23 },
	{ label: '24:00', value: '12am', key: 24 },
];
