import { useEffect, useState } from 'react';
import { TimesRangeDataSet } from './data';
import { filterTimeRangeOptions } from '../../utils';

export const useTimesRange = () => {
	const [optionsDataSet, setOptionsDataSet] = useState(TimesRangeDataSet);
	const [fromOptions, setFromOptions] = useState(TimesRangeDataSet);
	const [toOptions, setToOptions] = useState(TimesRangeDataSet);
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');

	const setOptionsBasedOnDay = (day) => {
		const isTomorrow = day === 'tomorrow';
		const getHours = new Date().getHours();
		const currentHour = getHours === 0 ? 24 : getHours;
		const todayFromOptions = TimesRangeDataSet.filter(
			(option) => option.key >= currentHour
		);
		const todayToOptions = TimesRangeDataSet.filter(
			(option) => option.key > currentHour
		);

		const fromData = isTomorrow ? TimesRangeDataSet : todayFromOptions;
		const toData = isTomorrow ? TimesRangeDataSet : todayToOptions;

		setOptionsDataSet(fromData);
		setFromOptions(filterTimeRangeOptions(fromData, toValue, false));
		setToOptions(filterTimeRangeOptions(toData, fromValue, true));
	};

	const onOptionSelected = (event) => {
		const currentElement = event.currentTarget;
		const { name, value } = currentElement;

		if (name === 'callFrom') {
			setToOptions(filterTimeRangeOptions(optionsDataSet, value, true));
			setFromValue(value);

			return;
		}

		setFromOptions(filterTimeRangeOptions(optionsDataSet, value, false));
		setToValue(value);
	};

	useEffect(() => {
		const getHours = new Date().getHours();
		const currentHour = getHours === 0 ? 24 : getHours;

		const newFromOptions = TimesRangeDataSet.filter(
			(option) => option.key >= currentHour
		);
		const newToOptions = TimesRangeDataSet.filter(
			(option) => option.key > currentHour
		);

		setOptionsDataSet(newFromOptions);
		setFromOptions(newFromOptions);
		setToOptions(newToOptions);
	}, []);

	return {
		fromOptions,
		toOptions,
		setOptionsBasedOnDay,
		onOptionSelected,
	};
};
