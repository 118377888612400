import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const TextInput = forwardRef(
	(
		{
			type,
			labelText,
			id,
			hintText,
			name,
			validationMessage,
			showValidation,
			className,
			...inputAttributes
		},
		ref
	) => {
		const [value, setValue] = useState('');
		const errorId = `${id}-error`;
		const hintTextId = `${id}-hint-text`;

		const handleOnChange = (e) => {
			setValue(e.target.value);
		};

		return (
			<div
				className={clsx(
					'mb-6 relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					showValidation ? 'before:bg-alert-400' : '',
					className
				)}
			>
				<div className="mb-4">
					<label
						htmlFor={id}
						className="text-brand-blue-400 text-base-f font-centra-medium"
					>
						{labelText}
					</label>
					{hintText ? <p id={hintTextId}>{hintText}</p> : null}
				</div>

				<input
					className={clsx(
						'block px-4 py-3 mb-3 border-2 rounded-xs border-blue-200 border-solid hover:bg-brand-pale-400 outline-none w-full appearance-none placeholder:text-grey-400 focus:border-blue-400 focus:placeholder:text-brand-blue-400 transition-colors duration-200 motion-reduce:transition-none',
						value.length > 0
							? 'border-brand-blue-400 bg-brand-pale-300'
							: '',
						showValidation
							? 'border-alert-400 placeholder:text-alert-500'
							: ''
					)}
					type={type}
					name={name}
					id={id}
					onChange={handleOnChange}
					aria-invalid={!!showValidation}
					aria-describedby={
						hintText ? `${hintTextId} ${errorId}` : errorId
					}
					value={value}
					ref={ref}
					{...inputAttributes}
				/>

				{showValidation ? (
					<p className="text-alert-500" id={errorId}>
						{validationMessage}
					</p>
				) : null}
			</div>
		);
	}
);

TextInput.defaultProps = {
	type: 'text',
	hintText: undefined,
	validationMessage: '',
	showValidation: false,
	className: '',
};

TextInput.propTypes = {
	type: PropTypes.string,
	labelText: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hintText: PropTypes.string,
	validationMessage: PropTypes.string,
	showValidation: PropTypes.bool,
	className: PropTypes.string,
};

export { TextInput };
